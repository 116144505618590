import type {Page_page_content as Content} from 'src/api/datocms/types/Page'
import {
  EmployeeList,
  IconBlock,
  ImageBlock,
  TextBlock,
  TextImageBlock,
  ValueList,
  BulletList,
  AccordionList,
  Banner,
  CtaBlock,
  HeroBanner,
  MailChimpForm,
  SupplierList,
  ProductSlider,
  VideoList,
  CollectionList,
  PageBanner,
  ContactForm,
  HtmlBlock,
  VideoBlock,
  BannerCollection,
  BannerSlider,
  ProductsList,
  CategoryProductList,
  CollectionProductList,
  VideoCover,
} from '../pageBlocks'

type EntryType = Content['__typename']

type ContentMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in EntryType]?: any
}

export const contentMap: ContentMap = {
  TextBlockRecord: TextBlock,
  ImageBlockRecord: ImageBlock,
  EmployeeListRecord: EmployeeList,
  TextImageBlockRecord: TextImageBlock,
  IconBlockRecord: IconBlock,
  BulletListRecord: BulletList,
  ValueListRecord: ValueList,
  SupplierListRecord: SupplierList,
  AccordionListRecord: AccordionList,
  BannerRecord: Banner,
  CtaBlockRecord: CtaBlock,
  MailchimpFormRecord: MailChimpForm,
  ProductSliderRecord: ProductSlider,
  HeroBannerRecord: HeroBanner,
  VideoListRecord: VideoList,
  CollectionListRecord: CollectionList,
  PageBannerRecord: PageBanner,
  ContactFormRecord: ContactForm,
  HtmlBlockRecord: HtmlBlock,
  VideoBlockRecord: VideoBlock,
  BannerCollectionRecord: BannerCollection,
  BannerSliderRecord: BannerSlider,
  ProductsListRecord: ProductsList,
  CategoryProductListRecord: CategoryProductList,
  CollectionProductListRecord: CollectionProductList,
  VideoCoverRecord: VideoCover,
}
