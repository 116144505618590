/* eslint-disable @next/next/no-img-element */
import React from 'react'
import type {Page_page_content_VideoCoverRecord} from 'src/api/datocms/types/Page'
import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'

interface props {
  width: number | null
  height: number | null
}

const VideoContainer = styled.div<props>`
  margin: 20px auto 0px auto;
  padding: 0px;
  background: #ef3f57;
  width: ${(props) => (props.width ? `${props.width}px` : '1400px')};
  height: ${(props) => (props.height ? `${props.height}px` : '600px')};
  max-width: 100%;
  .desktop {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .mobile {
    display: none;
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: fit-content;
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
`

const VideoCover = (props: Page_page_content_VideoCoverRecord): JSX.Element => {
  const {width, height} = props

  return (
    <Container>
      <VideoContainer width={width} height={height}>
        <video muted autoPlay loop playsInline className="desktop">
          <source src={props.desktop.video.mp4Url as string} type="video/mp4" />
        </video>
        <video muted autoPlay loop playsInline className="mobile">
          <source src={props.mobile.video.mp4Url as string} type="video/mp4" />
        </video>
      </VideoContainer>
    </Container>
  )
}

export default VideoCover
