import React from 'react'
import Image from 'next/image'
import type {TextImageBlockFields} from 'src/api/datocms/types/TextImageBlockFields'
import shadowStyles from 'src/styles/shadowStyles'
import {Col, Container, media, Row} from 'styled-bootstrap-grid'
import {ifProp} from 'styled-tools'
import styled, {css} from 'styled-components'
import {DatoStructuredText} from '../common'
import {BlockTitle, BlockWrapper} from '../layout'

interface BorderdProps {
  showBorder: boolean
}

const ImageWrapper = styled.div<BorderdProps>`
  ${ifProp(
    {showBorder: true},
    css`
      ${shadowStyles({
        color: 'red',
        outlined: true,
        length: 8,
        showOnHover: false,
      })}
    `,
  )}
  position: relative;
  overflow: hidden;
  display: grid;
  height: 100%;
  place-items: center;
  img {
    object-fit: contain !important;
    position: absolute !important;
    width: 40% !important;
    height: auto !important;
  }
`
interface MirroredProps {
  mirrored: boolean
}

const TextWrapper = styled.div<MirroredProps>`
  padding-bottom: 24px;
  max-width: 600px;

  ${media.sm`
    padding-right: ${ifProp({mirrored: true}, '0', '24px')};
    padding-left: ${ifProp({mirrored: true}, '24px', '0')};
`}
`

export default function TextImageBlock({
  title,
  text,
  image,
  mirrored,
  showBorder,
}: TextImageBlockFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <Row>
          <Col sm={6} smOrder={mirrored ? 2 : 1}>
            <TextWrapper mirrored={mirrored}>
              {!!title && <BlockTitle title={title} />}
              <DatoStructuredText content={text} />
            </TextWrapper>
          </Col>
          <Col sm={6} smOrder={mirrored ? 1 : 2}>
            <ImageWrapper showBorder={showBorder}>
              {image?.responsiveImage?.src ? (
                <Image src={image?.responsiveImage.src} layout="fill" />
              ) : null}
            </ImageWrapper>
          </Col>
        </Row>
      </Container>
    </BlockWrapper>
  )
}
