import React from 'react'
import type {BulletListFields} from 'src/api/datocms/types/BulletListFields'
import {gridTheme, theme} from 'src/themes'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {DynamicIcon} from '../common'
import {BlockTitle, BlockWrapper} from '../layout'
import {P, PSmall, Title6} from '../typography'

const Bullet = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px 0;
`

const BulletText = styled.div`
  padding-left: 24px;
`

export default function BulletList({
  title,
  description,
  bullets,
}: BulletListFields): JSX.Element {
  return (
    <BlockWrapper
      backgroundColor={theme.palette.background}
      customWidth={gridTheme.breakpoints.md}
    >
      <Container>
        {!!title && <BlockTitle title={title} />}
        <P>{description}</P>
        <Row>
          {bullets?.map(({id, iconSlug, title: currentTitle, text}) => (
            <Col sm={6} key={id}>
              <Bullet>
                <DynamicIcon icon={iconSlug} size={22} />
                <BulletText>
                  <Title6 withGutter>{currentTitle}</Title6>
                  <PSmall>{text}</PSmall>
                </BulletText>
              </Bullet>
            </Col>
          ))}
        </Row>
      </Container>
    </BlockWrapper>
  )
}
