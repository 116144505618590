import React from 'react'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import shadowStyles from 'src/styles/shadowStyles'
import Image from 'next/image'

import {paths, generatePath} from 'src/utils/paths'
import type {CollectionListFields_collections} from 'src/api/datocms/types/CollectionListFields'
import {
  CollectionInfoDocument,
  useCollectionInfoQuery,
} from 'src/api/saleor/generated'
import {H6} from '../typography'
import {Loader, Link} from '../common'

const CollectionTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  color: ${palette('secondary')};
  background: rgba(255, 255, 255, 0.8);
  transition: background-color 0.15s;
  border: 1px solid ${palette('black20')};
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette('white')};
  margin-bottom: 24px;
  ${shadowStyles({
    color: 'red',
    outlined: true,
    length: 8,
    showOnHover: true,
  })};

  &:hover&:not(:disabled) {
    ${CollectionTitleBox} {
      border: 1px solid ${palette('red')};
    }
  }

  &:focus,
  &:focus-visible {
    outline: 3px solid ${palette('sky')};
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const CardLink = styled.div``

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  &:after {
    content: '';
    display: block;
    padding-bottom: calc(100% - 48px);
  }
`

export default function CollectionCard({
  collectionId,
  categorySlug,
}: CollectionListFields_collections): JSX.Element | null {
  const {data, loading} = useCollectionInfoQuery({
    query: CollectionInfoDocument,
    variables: {id: collectionId ?? ''},
    skip: collectionId == null,
  })

  if (!data?.collection) {
    return null
  }

  const {id, name, backgroundImage} = data?.collection

  return (
    <Link
      href={
        categorySlug
          ? generatePath(paths.categories, categorySlug, 'landingPage=true')
          : generatePath(paths.productCollection, id)
      }
      shallow
    >
      <CardLink>
        <CardWrapper>
          <ImageContainer>
            {backgroundImage?.url ? (
              <Image
                src={backgroundImage?.url}
                layout="fill"
                objectFit="contain"
                objectPosition="center"
              />
            ) : null}
          </ImageContainer>
          {loading ? (
            <LoadingWrapper>
              <Loader size={32} />
            </LoadingWrapper>
          ) : (
            <CollectionTitleBox>
              <H6>{name || 'Vörusafn'}</H6>
            </CollectionTitleBox>
          )}
        </CardWrapper>
      </CardLink>
    </Link>
  )
}
