import type {ContactFormValues} from 'src/components/pageBlocks/ContactForm'
import type {EmploymentFormValues} from 'src/components/pageForms/employment/EmploymentFormFields'
import type {TransactionFormValues} from 'src/components/pageForms/transaction/TransactionFormFields'

export function makeTransactionMessage({
  name,
  ssn,
  phone,
  email,
  address,
  city,
  postalCode,
  poBox,
  website,
  cashierEmail,
  cashierName,
  cashierPhone,
  employees,
  adminEmail,
  amount,
  paymentMethod,
  contacts,
  auditors,
  owners,
}: TransactionFormValues): string {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html lang="is">
  <head>
    <meta charset="utf-8">
    <title>Umsókn um reikningsviðskipti</title>
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
  </head>

  <body>
    <div>
      <h3>Ný umsókn um reikningsviðskipti frá ${name}</h3>
      <p>Kennitala: ${ssn}</p>
      <p>Sími: ${phone}</p>
      <p>Netfang: ${email}</p>
      <p>Heimilisfang: ${address}, ${postalCode} ${city}</p>
      ${poBox ? `<p>Póstbox: ${poBox}</p>` : ''}
      ${website ? `<p>Vefsíða: ${website}</p>` : ''}
      <p>Fjöldi starfsmanna: ${employees}</p>
      <p>Nafn gjaldkera: ${cashierName}</p>
      <p>Netfang gjaldkera: ${cashierEmail}</p>
      <p>Sími gjaldkera: ${cashierPhone}</p>
      <h4>Tengiliðir</h4>
      ${contacts.map(
        (contact) =>
          `<div>
              <p>Nafn tengiliðs: ${contact.name}</p>
              ${contact.title ? `<p>Starfstitill: ${contact.title}</p>` : ''}
              ${contact.phone ? `<p>Sími: ${contact.phone}</p>` : ''}
              <p>Netfang tengiliðs: ${contact.email}</p>
              ${contact.job ? `<p>Starfsheiti: ${contact.job}</p>` : ''}
          </div>`,
      )}
      <h4>Úttektaraðilar</h4>
      ${auditors.map(
        (auditor) =>
          `<div>
            <p>Nafn úttektaraðila: ${auditor.name}</p>
            <p>Kennitala úttektaraðila: ${auditor.ssn}</p>
          </div>`,
      )}
      <h4>Eigendur</h4>
      ${owners.map(
        (owner) =>
          `<div>
            <p>Nafn eiganda: ${owner.name}</p>
            <p>Kennitala eiganda: ${owner.ssn}</p>
          </div>`,
      )}
      <p>Aðgangur að vefverslun RV sendist á netfangið: ${adminEmail}</p>
      <p>Áætluð viðskipti á mánuði kr: ${amount}</p>
      <p>Greiðsluháttur: ${paymentMethod}</p>
    </div>
  </body>
  </html>`
}

export function makeEmploymentMessage({
  name,
  email,
  ssn,
  address,
  postalCode,
  city,
  phone,
  education,
  family,
  hobbies,
  hours,
  job,
  references,
  smoker,
  start,
  time,
  type,
}: EmploymentFormValues): string {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html lang="is">
  <head>
    <meta charset="utf-8">
    <title>Umsókn um starf</title>
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
  </head>

  <body>
    <div>
      <h3>Ný umsókn um starf frá ${name}</h3>
      <p>Kennitala: ${ssn}</p>
      <p>Sími: ${phone}</p>
      <p>Netfang: ${email}</p>
      <p>Heimilisfang: ${address}, ${postalCode} ${city}</p>
      <p>Sótt um: ${type}</p>
      <p>Núverandi starf: ${job}</p>
      <p>Fjölskylduhagir: ${family}</p>
      <p>Áhugamál: ${hobbies}</p>
      <p>Menntun:</p>
      <p style="white-space: pre-wrap;">${education}</p>
      <p>Meðmæli:</p>
      <p style="white-space: pre-wrap;">${references}</p>
      <p>Vinnutími frá/til: ${hours}</p>
      <p>Reykir: ${smoker}</p>
      <p>Getur byrjað: ${start}</p>
      <p>${time}</p>
    </div>
  </body>
  </html>`
}

export function makeContactFormMessage({
  name,
  email,
  message,
}: ContactFormValues): string {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html lang="is">
  <head>
    <meta charset="utf-8">
    <title>Ný skilaboð frá rv.is</title>
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
  </head>

  <body>
    <div>
      <h3>Ný skilaboð frá ${name}</h3>
      <p>Netfang: ${email}</p>
      <p>Skilaboð:</p>
      <p style="white-space: pre-wrap;">${message}</p>
    </div>
  </body>
  </html>`
}
