import React, {useState} from 'react'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {palette} from 'styled-tools'
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon'
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon'
import {DatoStructuredText, Expandable} from '../common'
import {PLarge, Title6} from '../typography'
import {fluidRange} from '../../utils/styles'
import {BlockTitle, BlockWrapper} from '../layout'
import {theme} from '../../themes'
import type {
  AccordionListFields,
  AccordionListFields_accordions as AccordionType,
} from '../../api/datocms/types/AccordionListFields'

interface OpenBtnProps {
  isOpen: boolean
}

interface AccordionProps {
  isOpen: boolean
}

const ListWrapper = styled.div`
  padding-top: 16px;
`

const AccordionHead = styled.div`
  padding: 10px;
  cursor: pointer;
`

const expandedCSS = css`
  box-shadow: inset 0px -3px 0px #ef3f57, inset 0px 1px 0px #ef3f57;
`

const Accordion = styled.div<AccordionProps>`
  background-color: ${palette('white')};
  ${fluidRange('margin-bottom', '8px', '16px')};
  box-shadow: inset 0px -1px 0px #cecddc, inset 0px 1px 0px #cecddc;
  transition: box-shadow 1s;

  ${(props) => props.isOpen && expandedCSS}
`

const StructuredTextWrapper = styled.div`
  padding: 0 10px;

  p {
    font-size: 0.875rem;
    color: ${palette('black70')};
  }
`

const OpenBtn = styled.button<OpenBtnProps>`
  border: 0;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;

  &:hover {
    .remixicon-icon {
      fill: ${palette('red')};
    }
  }

  &:focus {
    outline: none;
  }
`

const BtnWrapper = styled.div`
  text-align: right;
`

function AccordionItem({title, content}: AccordionType): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <Accordion isOpen={isOpen}>
      <AccordionHead onClick={() => setIsOpen(!isOpen)}>
        <Row alignItems="center">
          <Col col={10} sm={11}>
            <Title6>{title}</Title6>
          </Col>
          <Col col={2} sm={1}>
            <BtnWrapper>
              <OpenBtn isOpen={isOpen}>
                {isOpen ? (
                  <ArrowUpSLineIcon size={30} color={theme.palette.black} />
                ) : (
                  <ArrowDownSLineIcon size={30} color={theme.palette.black} />
                )}
              </OpenBtn>
            </BtnWrapper>
          </Col>
        </Row>
      </AccordionHead>
      <Expandable expanded={isOpen}>
        <StructuredTextWrapper>
          {content ? <DatoStructuredText content={content} /> : null}
        </StructuredTextWrapper>
      </Expandable>
    </Accordion>
  )
}

export default function AccordionList({
  title,
  description,
  accordions,
  split = false,
}: AccordionListFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <BlockTitle title={title ?? ''} />
        <PLarge withGutter>{description}</PLarge>
        <ListWrapper>
          <Row>
            {accordions?.map((props: AccordionType) => (
              <Col
                col={12}
                md={split ? 6 : 12}
                key={props.id}
                alignSelf="start"
              >
                <AccordionItem {...props} />
              </Col>
            ))}
          </Row>
        </ListWrapper>
      </Container>
    </BlockWrapper>
  )
}
