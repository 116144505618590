import React from 'react'
import type {MailchimpFormFields} from 'src/api/datocms/types/MailchimpFormFields'
import {Container} from 'styled-bootstrap-grid'
import Mailchimp from 'react-mailchimp-form'
import styled from 'styled-components'
import {BlockTitle, BlockWrapper} from '../layout'
import {PLarge} from '../typography'
import {inputCommon} from '../form/Input'
import {buttonStyles, buttonTextStyles} from '../buttons/buttonStyles'

const FormWrapper = styled.div`
  max-width: 700px;
  margin: auto;
  input {
    ${inputCommon}
    margin-bottom: 16px;
  }

  button {
    ${buttonStyles({color: 'secondary'})};
    ${buttonTextStyles()};
    color: white;
    justify-content: center;
  }

  .msg-alert {
    text-align: center;
    margin-top: 16px;
  }
`

export default function MailChimpForm({
  title,
  description,
  formUrl,
  inputs,
  submitLabel,
}: MailchimpFormFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <FormWrapper>
          {!!title && <BlockTitle title={title} />}
          <PLarge withGutter>{description}</PLarge>
          <Mailchimp
            action={formUrl}
            fields={inputs?.map((input) => ({
              name: input.name,
              placeholder: input.label,
              type: input.inputType,
              required: input.required,
            }))}
            messages={{
              sending: 'Sendi...',
              success: 'Takk fyrir!',
              error: 'Úps! Eitthvað fór úrskeiðis.',
              empty: 'Þú verður að slá inn tölvupóstfang',
              duplicate: 'Tölvupóstfang nú þegar skráð',
              button: submitLabel,
            }}
          />
        </FormWrapper>
      </Container>
    </BlockWrapper>
  )
}
