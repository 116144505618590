/* eslint-disable @next/next/no-img-element */
import React from 'react'
import type {BannerFields} from 'src/api/datocms/types/BannerFields'
import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import Slider from 'react-slick'
import Banner from './Banner'

interface BannerSliderProps {
  banner: BannerFields[]
  slideSpeed: number
}

const BannerContainer = styled.div`
  padding: 20px;

  .slick-arrow::before {
    color: rgb(239, 63, 87);
    opacity: 1;
  }
  .slick-dots li button::before {
    color: rgb(239, 63, 87);
    font-size: 10px;
  }

  .slick-slider div {
    padding: 0px;
  }

  @media screen and (max-width: 768px) {
    padding: 5px;

    .slick-arrow {
      display: none !important;
    }
    .slick-slider,
    .slick-list,
    .slick-active,
    .slick-current {
      padding: 0px !important;
    }

    .slick-slider div {
      padding: 0px;
    }
  }
`

export default function BannerSlider({
  banner,
  slideSpeed,
}: BannerSliderProps): JSX.Element | null {
  if (!banner || !banner.length) {
    return null
  }

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: slideSpeed ? slideSpeed * 1000 : 5000,
    cssEase: 'linear',
    swipeToSlide: true,
  }
  return (
    <BannerContainer>
      <Container>
        <Slider {...settings}>
          {banner.map((item) => (
            <Banner
              key={item.id}
              image={item?.image}
              link={item.link}
              title={item?.title}
              linkLabel={item?.linkLabel}
              description={item?.description}
              heightMob={item?.heightMob}
              __typename="BannerRecord"
              id={undefined}
            />
          ))}
        </Slider>
      </Container>
    </BannerContainer>
  )
}
