import React from 'react'
import styled from 'styled-components'
import {Col, Row} from 'styled-bootstrap-grid'
import {H3} from 'src/components/typography/Headings'
import type {Page_page_content_CategoryProductListRecord} from 'src/api/datocms/types/Page'
import {ProductListPattern} from 'src/api/datocms/types/Page'
import CustomSlider from '../common/CustomSlider'
import {ProductCard} from '../products'

const ProductListContainer = styled.div`
  .slider-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 1.5;
  }
  .slider-wrapper {
    margin-left: 10px;
    margin-bottom: 20px;
  }
`

const ProductsBlock = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  H3 {
    margin-left: 20px;
    margin-bottom: 20px;
  }
`

interface Product {
  id: string
  slug: string
}

export default function ProductsList(
  props: Page_page_content_CategoryProductListRecord,
): JSX.Element {
  const {productList, displayPattern, title} = props
  const products = JSON.parse(productList as string)

  return (
    <ProductListContainer>
      {displayPattern === ProductListPattern.Slider ? (
        <CustomSlider xl={3} lg={3} md={2} sm={1.1} title={title}>
          {products
            ?.map((product: Product) => (
              <ProductCard key={product.id} slug={product.slug} />
            ))
            .filter(Boolean)}
        </CustomSlider>
      ) : (
        <ProductsBlock>
          <Row>
            <H3>{title}</H3>
          </Row>
          <Row>
            {products?.map((product: Product) => (
              <Col sm={6} xs={12} md={4} lg={4} xl={4}>
                <ProductCard key={product.id} slug={product.slug} />
              </Col>
            ))}
          </Row>
        </ProductsBlock>
      )}
    </ProductListContainer>
  )
}
