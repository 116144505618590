/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Page
// ====================================================

export interface Page_page_content_TextBlockRecord_text_blocks_image_responsiveImage {
  __typename: "ResponsiveImage";
  width: any;
  webpSrcSet: string;
  title: string | null;
  srcSet: string;
  src: string;
  sizes: string;
  height: any;
  bgColor: string | null;
  base64: string | null;
  aspectRatio: any;
  alt: string | null;
}

export interface Page_page_content_TextBlockRecord_text_blocks_image {
  __typename: "FileField";
  url: string;
  alt: string | null;
  responsiveImage: Page_page_content_TextBlockRecord_text_blocks_image_responsiveImage | null;
}

export interface Page_page_content_TextBlockRecord_text_blocks {
  __typename: "ImageBlockRecord";
  id: any;
  image: Page_page_content_TextBlockRecord_text_blocks_image | null;
}

export interface Page_page_content_TextBlockRecord_text {
  __typename: "TextBlockModelTextField";
  value: any;
  blocks: Page_page_content_TextBlockRecord_text_blocks[];
}

export interface Page_page_content_TextBlockRecord {
  __typename: "TextBlockRecord";
  id: any;
  text: Page_page_content_TextBlockRecord_text | null;
}

export interface Page_page_content_BannerCollectionRecord {
  __typename: "BannerCollectionRecord";
  id: any;
  brochures: Page_page_content_BannerRecord[];
}

export interface Page_page_content_BannerSliderRecord {
  __typename: "BannerSliderRecord";
  id: any;
  banner: Page_page_content_BannerRecord[];
}

export interface Page_page_content_ImageBlockRecord_image_responsiveImage {
  __typename: "ResponsiveImage";
  srcSet: string;
  webpSrcSet: string;
  sizes: string;
  src: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt: string | null;
  title: string | null;
  bgColor: string | null;
  base64: string | null;
}

export interface Page_page_content_ImageBlockRecord_image {
  __typename: "FileField";
  responsiveImage: Page_page_content_ImageBlockRecord_image_responsiveImage | null;
  url: string;
}

export interface Page_page_content_ImageBlockRecord {
  __typename: "ImageBlockRecord";
  id: any;
  image: Page_page_content_ImageBlockRecord_image | null;
}

export interface Page_page_content_VideoListRecord_videos_video {
  __typename: "VideoField";
  title: string | null;
  provider: string | null;
  providerUid: string | null;
}

export interface Page_page_content_VideoListRecord_videos {
  __typename: "VideoRecord";
  id: any;
  title: string | null;
  description: string | null;
  video: Page_page_content_VideoListRecord_videos_video | null;
}

export interface Page_page_content_VideoListRecord {
  __typename: "VideoListRecord";
  id: any;
  title: string | null;
  description: string | null;
  videoPerLine: any | null;
  displayPattern: any | null;
  videos: Page_page_content_VideoListRecord_videos[];
}

export interface Page_page_content_VideoBlockRecord_video {
  __typename: "VideoField";
  title: string | null;
  provider: string | null;
  providerUid: string | null;
}

export interface Page_page_content_VideoBlockRecord {
  __typename: "VideoBlockRecord";
  id: any;
  title: string | null;
  description: string | null;
  video: Page_page_content_VideoBlockRecord_video | null;
}

export interface Page_page_content_EmployeeListRecord_employees_department {
  __typename: "DepartmentRecord";
  name: string | null;
}

export interface Page_page_content_EmployeeListRecord_employees_image_responsiveImage {
  __typename: "ResponsiveImage";
  srcSet: string;
  webpSrcSet: string;
  sizes: string;
  src: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt: string | null;
  title: string | null;
  bgColor: string | null;
  base64: string | null;
}

export interface Page_page_content_EmployeeListRecord_employees_image {
  __typename: "FileField";
  responsiveImage: Page_page_content_EmployeeListRecord_employees_image_responsiveImage | null;
  url: string;
}

export interface Page_page_content_EmployeeListRecord_employees {
  __typename: "EmployeeRecord";
  id: any;
  name: string | null;
  role: string | null;
  email: string | null;
  officePhone: string | null;
  mobilePhone: string | null;
  comment: string | null;
  department: Page_page_content_EmployeeListRecord_employees_department | null;
  image: Page_page_content_EmployeeListRecord_employees_image | null;
}

export interface Page_page_content_EmployeeListRecord {
  __typename: "EmployeeListRecord";
  id: any;
  title: string | null;
  employees: Page_page_content_EmployeeListRecord_employees[];
}

export interface Page_page_content_TextImageBlockRecord_text {
  __typename: "TextImageBlockModelTextField";
  value: any;
}

export interface Page_page_content_TextImageBlockRecord_image_responsiveImage {
  __typename: "ResponsiveImage";
  srcSet: string;
  webpSrcSet: string;
  sizes: string;
  src: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt: string | null;
  title: string | null;
  bgColor: string | null;
  base64: string | null;
}

export interface Page_page_content_TextImageBlockRecord_image {
  __typename: "FileField";
  responsiveImage: Page_page_content_TextImageBlockRecord_image_responsiveImage | null;
}

export interface Page_page_content_TextImageBlockRecord {
  __typename: "TextImageBlockRecord";
  id: any;
  title: string | null;
  mirrored: any | null;
  showBorder: any | null;
  text: Page_page_content_TextImageBlockRecord_text | null;
  image: Page_page_content_TextImageBlockRecord_image | null;
}

export interface Page_page_content_IconBlockRecord_icons {
  __typename: "IconRecord";
  id: any;
  iconSlug: string | null;
  text: string | null;
}

export interface Page_page_content_IconBlockRecord {
  __typename: "IconBlockRecord";
  id: any;
  icons: Page_page_content_IconBlockRecord_icons[];
}

export interface Page_page_content_BulletListRecord_bullets {
  __typename: "BulletRecord";
  id: any;
  iconSlug: string | null;
  title: string | null;
  text: string | null;
}

export interface Page_page_content_BulletListRecord {
  __typename: "BulletListRecord";
  id: any;
  title: string | null;
  description: string | null;
  bullets: Page_page_content_BulletListRecord_bullets[];
}

export interface Page_page_content_ValueListRecord_values_text {
  __typename: "ValueModelTextField";
  value: any;
}

export interface Page_page_content_ValueListRecord_values_image_responsiveImage {
  __typename: "ResponsiveImage";
  srcSet: string;
  webpSrcSet: string;
  sizes: string;
  src: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt: string | null;
  title: string | null;
  bgColor: string | null;
  base64: string | null;
}

export interface Page_page_content_ValueListRecord_values_image {
  __typename: "FileField";
  responsiveImage: Page_page_content_ValueListRecord_values_image_responsiveImage | null;
  url: string;
}

export interface Page_page_content_ValueListRecord_values {
  __typename: "ValueRecord";
  id: any;
  title: string | null;
  text: Page_page_content_ValueListRecord_values_text | null;
  image: Page_page_content_ValueListRecord_values_image | null;
}

export interface Page_page_content_ValueListRecord {
  __typename: "ValueListRecord";
  id: any;
  title: string | null;
  small: any | null;
  values: Page_page_content_ValueListRecord_values[];
}

export interface Page_page_content_SupplierListRecord_suppliers_text {
  __typename: "SupplierModelTextField";
  value: any;
}

export interface Page_page_content_SupplierListRecord_suppliers_logo_responsiveImage {
  __typename: "ResponsiveImage";
  srcSet: string;
  webpSrcSet: string;
  sizes: string;
  src: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt: string | null;
  title: string | null;
  bgColor: string | null;
  base64: string | null;
}

export interface Page_page_content_SupplierListRecord_suppliers_logo {
  __typename: "FileField";
  responsiveImage: Page_page_content_SupplierListRecord_suppliers_logo_responsiveImage | null;
  url: string;
}

export interface Page_page_content_SupplierListRecord_suppliers {
  __typename: "SupplierRecord";
  id: any;
  name: string | null;
  link: string | null;
  text: Page_page_content_SupplierListRecord_suppliers_text | null;
  logo: Page_page_content_SupplierListRecord_suppliers_logo | null;
}

export interface Page_page_content_SupplierListRecord {
  __typename: "SupplierListRecord";
  id: any;
  title: string | null;
  suppliers: Page_page_content_SupplierListRecord_suppliers[];
}

export interface Page_page_content_AccordionListRecord_accordions_content {
  __typename: "AccordionModelContentField";
  value: any;
}

export interface Page_page_content_AccordionListRecord_accordions {
  __typename: "AccordionRecord";
  id: any;
  title: string | null;
  content: Page_page_content_AccordionListRecord_accordions_content | null;
}

export interface Page_page_content_AccordionListRecord {
  __typename: "AccordionListRecord";
  id: any;
  title: string | null;
  description: string | null;
  split: any | null;
  accordions: Page_page_content_AccordionListRecord_accordions[];
}

export interface Page_page_content_BannerRecord_image_responsiveImage {
  __typename: "ResponsiveImage";
  srcSet: string;
  webpSrcSet: string;
  sizes: string;
  src: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt: string | null;
  title: string | null;
  bgColor: string | null;
  base64: string | null;
}

export interface Page_page_content_BannerRecord_image {
  __typename: "FileField";
  responsiveImage: Page_page_content_BannerRecord_image_responsiveImage | null;
  url: string;
}

export interface Page_page_content_BannerRecord {
  __typename: "BannerRecord";
  id: any;
  image: Page_page_content_BannerRecord_image | null;
  title: string | null;
  description: string | null;
  link: string | null;
  linkLabel: string | null;
  heightMob?: number | null;
}

export interface Page_page_content_CtaBlockRecord {
  __typename: "CtaBlockRecord";
  id: any;
  title: string | null;
  description: string | null;
  link: string | null;
  linkLabel: string | null;
}

export interface Page_page_content_MailchimpFormRecord_inputs {
  __typename: "InputRecord";
  id: any;
  name: string | null;
  label: string | null;
  inputType: string | null;
  required: any | null;
}

export interface Page_page_content_MailchimpFormRecord {
  __typename: "MailchimpFormRecord";
  id: any;
  title: string | null;
  description: string | null;
  formUrl: string | null;
  submitLabel: string | null;
  inputs: Page_page_content_MailchimpFormRecord_inputs[];
}

export interface Page_page_content_ProductSliderRecord_collection {
  __typename: "CollectionRecord";
  collectionId: string | null;
}

export interface Page_page_content_ProductSliderRecord {
  __typename: "ProductSliderRecord";
  id: any;
  collectionId: string | null;
  collection: Page_page_content_ProductSliderRecord_collection | null;
}

export interface Page_page_content_HeroBannerRecord_image_responsiveImage {
  __typename: "ResponsiveImage";
  srcSet: string;
  webpSrcSet: string;
  sizes: string;
  src: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt: string | null;
  title: string | null;
  bgColor: string | null;
  base64: string | null;
}

export interface Page_page_content_HeroBannerRecord_image {
  __typename: "FileField";
  responsiveImage: Page_page_content_HeroBannerRecord_image_responsiveImage | null;
}

export interface Page_page_content_HeroBannerRecord {
  __typename: "HeroBannerRecord";
  id: any;
  title: string | null;
  description: string | null;
  label: string | null;
  buttonText: string | null;
  buttonLink: string | null;
  image: Page_page_content_HeroBannerRecord_image | null;
}

export interface Page_page_content_CollectionListRecord_collections {
  __typename: "CollectionRecord";
  collectionId: string | null;
}

export interface Page_page_content_CollectionListRecord {
  __typename: "CollectionListRecord";
  id: any;
  collections: Page_page_content_CollectionListRecord_collections[];
}

export interface Page_page_content_PageBannerRecord_desktopImage_responsiveImage {
  __typename: "ResponsiveImage";
  srcSet: string;
  webpSrcSet: string;
  sizes: string;
  src: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt: string | null;
  title: string | null;
  bgColor: string | null;
  base64: string | null;
}

export interface Page_page_content_PageBannerRecord_desktopImage {
  __typename: "FileField";
  responsiveImage: Page_page_content_PageBannerRecord_desktopImage_responsiveImage | null;
}

export interface Page_page_content_PageBannerRecord_mobileImage_responsiveImage {
  __typename: "ResponsiveImage";
  srcSet: string;
  webpSrcSet: string;
  sizes: string;
  src: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt: string | null;
  title: string | null;
  bgColor: string | null;
  base64: string | null;
}

export interface Page_page_content_PageBannerRecord_mobileImage {
  __typename: "FileField";
  responsiveImage: Page_page_content_PageBannerRecord_mobileImage_responsiveImage | null;
}

export interface Page_page_content_PageBannerRecord {
  __typename: "PageBannerRecord";
  id: any;
  desktopImage: Page_page_content_PageBannerRecord_desktopImage | null;
  mobileImage: Page_page_content_PageBannerRecord_mobileImage | null;
  link: string | null;
}

export interface Page_page_content_ContactFormRecord {
  __typename: "ContactFormRecord";
  id: any;
  title: string | null;
  description: string | null;
  toEmail: string | null;
  subject: string | null;
}

export interface Page_page_content_HtmlBlockRecord {
  __typename: "HtmlBlockRecord";
  id: any;
  html: string | null;
}
export enum ProductListPattern {
  Slider = "slider",
  Grid = "grid"
}
export enum VideoListPattern {
  Slider = "slider",
  Grid = "grid"
}
export interface Page_page_content_ProductsListRecord {
  __typename: "ProductsListRecord";
  id: string;
  displayPattern: ProductListPattern
  title: string | null;
  products: Array<{
    id: string;
    product: string;
    __typename: "ProductBlockRecord"
  }>
}

export interface Page_page_content_CategoryProductListRecord {
  __typename: "CategoryProductListRecord";
  id: string;
  displayPattern: ProductListPattern
  title: string | null;
  productList: string;
}
export interface Page_page_content_CollectionProductListRecord {
  __typename: "CollectionProductListRecord";
  id: string;
  displayPattern: ProductListPattern
  title: string | null;
  productList: string;
}

export interface Page_page_content_VideoCoverRecord_CoverVideo {
  mp4Url: string | null;
  streamingUrl: string | null;
}

export interface Page_page_content_VideoCoverRecord_Video {
  __typename: "VideoField";
  video: Page_page_content_VideoCoverRecord_CoverVideo;
}


export interface Page_page_content_VideoCoverRecord {
  __typename: "VideoCoverRecord";
  id: any;
  height: number;
  width: number;
  desktop: Page_page_content_VideoCoverRecord_Video;
  mobile: Page_page_content_VideoCoverRecord_Video;
}


export type Page_page_content = Page_page_content_TextBlockRecord | Page_page_content_ImageBlockRecord | Page_page_content_VideoListRecord | Page_page_content_VideoBlockRecord | Page_page_content_EmployeeListRecord | Page_page_content_TextImageBlockRecord | Page_page_content_IconBlockRecord | Page_page_content_BulletListRecord | Page_page_content_ValueListRecord | Page_page_content_SupplierListRecord | Page_page_content_AccordionListRecord | Page_page_content_BannerRecord | Page_page_content_CtaBlockRecord | Page_page_content_MailchimpFormRecord | Page_page_content_ProductSliderRecord | Page_page_content_HeroBannerRecord | Page_page_content_CollectionListRecord | Page_page_content_PageBannerRecord | Page_page_content_ContactFormRecord | Page_page_content_HtmlBlockRecord | Page_page_content_BannerCollectionRecord | Page_page_content_ProductsListRecord | Page_page_content_CategoryProductListRecord | Page_page_content_BannerSliderRecord | Page_page_content_CollectionProductListRecord | Page_page_content_VideoCoverRecord;

export interface Page_page {
  __typename: "PageRecord";
  id: any;
  heading: string | null;
  path: string | null;
  content: Page_page_content[];
}

export interface Page {
  /**
   * Returns a specific record
   */
  page: Page_page | null;
}

export interface PageVariables {
  path?: string | null;
}

