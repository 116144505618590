import React from 'react'
import type {
  VideoListFields,
  VideoListFields_videos,
} from 'src/api/datocms/types/VideoListFields'
import {lgColSize, mdColSize, smColSize} from 'src/utils/numbers'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {VideoListPattern} from 'src/api/datocms/types/Page'
import Video from '../common/Video'
import {BlockTitle, BlockWrapper} from '../layout'
import {PLarge} from '../typography'
import CustomSlider from '../common/CustomSlider'

const VideoWrapper = styled.div`
  height: 100%;
  max-width: 800px;
  margin: auto;
`

export default function VideoList({
  title,
  description,
  videos,
  videoPerLine,
  displayPattern,
}: VideoListFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        {!!title && <BlockTitle title={title} />}
        {!!description && <PLarge>{description}</PLarge>}

        {displayPattern === VideoListPattern.Slider ? (
          <CustomSlider xl={4} lg={4} md={2} sm={1.1} title={title}>
            {videos?.map((video: VideoListFields_videos) => (
              <VideoWrapper>
                <Video {...video} />
              </VideoWrapper>
            ))}
          </CustomSlider>
        ) : (
          <Row>
            {videos?.map((video: VideoListFields_videos) => (
              <Col
                key={video.id}
                col={12}
                sm={smColSize(videoPerLine)}
                md={mdColSize(videoPerLine)}
                lg={lgColSize(videoPerLine)}
              >
                <VideoWrapper>
                  <Video {...video} />
                </VideoWrapper>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </BlockWrapper>
  )
}
