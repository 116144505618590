import React from 'react'
import type {CtaBlockFields} from 'src/api/datocms/types/CtaBlockFields'
import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import AnchorButton from '../buttons/AnchorButton'
import {BlockWrapper} from '../layout'
import {H1, PLarge} from '../typography'

const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${H1} {
    width: inherit;
  }
`

export default function CtaBlock({
  title,
  description,
  link,
  linkLabel,
}: CtaBlockFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <CtaWrapper>
          <H1 withGutter>{title}</H1>
          <PLarge withGutter>{description}</PLarge>
          {link && (
            <AnchorButton color="red" outlined href={link}>
              {linkLabel}
            </AnchorButton>
          )}
        </CtaWrapper>
      </Container>
    </BlockWrapper>
  )
}
