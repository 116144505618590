export async function sendEmail(
  name: string,
  subject: string,
  message: string,
  file?: File,
) {
  const data = new FormData()
  data.append('name', name)
  data.append('subject', subject)
  data.append('message', message)
  if (file) {
    data.append('file', file)
  }

  return fetch('/api/sendgrid', {
    body: data,
    method: 'POST',
  })
}
