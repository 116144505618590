import React from 'react'
import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {theme} from 'src/themes'
import {DynamicIcon} from '../common'
import type {
  IconBlockFields,
  IconBlockFields_icons as Icon,
} from '../../api/datocms/types/IconBlockFields'

import {H4} from '../typography'

const IconContainer = styled(Container)`
  display: flex;
  justify-content: space-evenly;
  margin: 32px auto;
`

const IconSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  ${H4} {
    margin-top: 20px;
    width: inherit;
    @media (max-width: ${theme.screenSize.sizeSM}) {
      font-size: 20px;
    }
  }
`

export default function IconBlock({icons}: IconBlockFields): JSX.Element {
  return (
    <IconContainer>
      {icons?.map(({id, iconSlug, text}: Icon) => (
        <IconSection key={id}>
          <DynamicIcon icon={iconSlug} size={50} />
          <H4>{text}</H4>
        </IconSection>
      ))}
    </IconContainer>
  )
}
