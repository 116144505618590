import {useFormikContext} from 'formik'
import {useRef} from 'react'
import styled from 'styled-components'
import {Button} from '../buttons'
import type {EmploymentFormValues} from '../pageForms/employment/EmploymentFormFields'
import {InputLabel, InputSleeve, InputWrapper} from './Input'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  valid?: boolean
  label?: string
  withGutter?: boolean
  info?: string
}

const Input = styled.input`
  display: none;
`

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
`

function FormikFileInput(props: InputProps): JSX.Element {
  const {
    values,
    setFieldValue,
    getFieldMeta,
  } = useFormikContext<EmploymentFormValues>()
  const fileRef = useRef<HTMLInputElement>(null)
  const meta = getFieldMeta('file')
  const showError = !!(meta.error && meta.touched)

  return (
    <InputWrapper withGutter={props.withGutter}>
      <InputLabel htmlFor={props.id} disabled={props.disabled}>
        {props.label}
      </InputLabel>
      <InputSleeve
        errorMsg={meta.error}
        showError={showError}
        info={props.info}
      >
        <Input
          name="file"
          type="file"
          accept="application/pdf"
          onChange={(event) => {
            if (!event?.currentTarget.files || !event?.currentTarget.files[0]) {
              return
            }
            setFieldValue('file', event.currentTarget.files[0])
          }}
          ref={fileRef}
        />
        <FieldWrapper>
          <Button
            outlined
            onClick={() => fileRef.current && fileRef.current.click()}
            style={{marginRight: '16px'}}
            type="button"
          >
            Velja skrá
          </Button>
          {values.file?.name}
        </FieldWrapper>
      </InputSleeve>
    </InputWrapper>
  )
}

export default FormikFileInput
