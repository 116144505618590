import type {FormikProps} from 'formik'
import {withFormik} from 'formik'
import React from 'react'
import {sendEmail} from 'src/api/sendgrid'
import {makeContactFormMessage} from 'src/utils/email'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {Col, Row} from 'styled-bootstrap-grid'
import type {ContactFormFields} from 'src/api/datocms/types/ContactFormFields'
import {validateEmail} from 'src/utils/validation'
import {Button} from '../buttons'
import FormikInput from '../form/Input'
import FormikFileInput from '../form/FileInput'
import {BlockTitle, BlockWrapper} from '../layout'
import {PLarge} from '../typography'

interface MyFormProps {
  subject: string
}

const ContactFormWrapper = styled.div`
  max-width: 800px;
  margin: auto;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Status = styled.div`
  margin-top: 16px;
  text-align: center;
`
const Error = styled.div`
  margin-top: 16px;
  text-align: center;
  color: ${palette('red')};
`

export interface ContactFormValues {
  name: string
  email: string
  message: string
  file?: File
}

interface ContactFormErrorProps {
  name?: string
  email?: string
  message?: string
}

const Form = ({
  handleSubmit,
  isSubmitting,
  status,
}: FormikProps<ContactFormValues>): JSX.Element => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col col={12}>
        <FormikInput label="Nafn" name="name" />
      </Col>
      <Col col={12}>
        <FormikInput label="Netfang" name="email" />
      </Col>
      <Col col={12}>
        <FormikInput label="Skilaboð" name="message" type="textarea" />
      </Col>
      <Col col={12}>
        <FormikFileInput label="Viðhengi" name="file" type="file" withGutter />
      </Col>
    </Row>
    <ButtonWrapper>
      <Button type="submit" disabled={isSubmitting}>
        Senda skilaboð
      </Button>
      {!!status?.success && <Status>{status.success}</Status>}
      {!!status?.error && <Error>{status.error}</Error>}
    </ButtonWrapper>
  </form>
)

const ContactFormik = withFormik<MyFormProps, ContactFormValues>({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    message: '',
    file: undefined,
  }),

  validate: ({name, email, message}) => {
    const errors: ContactFormErrorProps = {}

    if (!name) {
      errors.name = 'Hér vantar nafn'
    }

    if (!message) {
      errors.message = 'Hér vantar skilaboð'
    }

    if (!email) {
      errors.email = 'Hér vantar netfang'
    } else if (!validateEmail(email)) {
      errors.email = 'Netfang ekki á réttu formi'
    }

    return errors
  },

  handleSubmit: (values, {props: {subject}, setSubmitting, setStatus}) => {
    const message = makeContactFormMessage(values)
    sendEmail(values.name, subject, message, values.file).then((res) => {
      if (res.status !== 200) {
        setSubmitting(false)
        setStatus({
          error: 'Eitthvað fór úrskeiðis. Vinsamlegast reyndu aftur.',
        })
      } else {
        setStatus({success: 'Skilaboð móttekin'})
      }
    })
  },
  displayName: 'ContactForm',
})(Form)

export default function ContactForm({
  title,
  description,
  subject,
}: ContactFormFields): JSX.Element {
  return (
    <BlockWrapper>
      <ContactFormWrapper>
        {!!title && <BlockTitle title={title} />}
        {!!description && <PLarge withGutter>{description}</PLarge>}
        <ContactFormik subject={subject ?? ''} />
      </ContactFormWrapper>
    </BlockWrapper>
  )
}
