import React from 'react'
import Image from 'next/image'
import ArrowRightUpLineIcon from 'remixicon-react/ArrowRightUpLineIcon'
import type {
  SupplierListFields,
  SupplierListFields_suppliers as SupplierType,
} from 'src/api/datocms/types/SupplierListFields'
import shadowStyles from 'src/styles/shadowStyles'
import {theme} from 'src/themes'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {DatoStructuredText} from '../common'
import Link from '../common/Link'
import {BlockTitle, BlockWrapper} from '../layout'

const Logo = styled.div`
  position: relative;
  width: 100%;
  height: 80px;
`

const Content = styled.div`
  position: relative;
  padding-top: 16px;
  margin-top: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    border-bottom: 1px solid ${palette('black20')};
  }
`

const ArrowContainer = styled.div`
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
`

const CardInner = styled.div`
  background-color: ${palette('white')};
  border: 1px solid ${palette('black20')};
  padding: 20px;
  padding-bottom: 0;
  height: 100%;
  ${shadowStyles({
    color: 'red',
    outlined: false,
    length: 8,
    showOnHover: true,
  })};
`

const Card = styled.div`
  height: 100%;
  padding-bottom: 24px;

  &:hover {
    ${CardInner} {
      border-color: ${palette('red')};
    }

    ${ArrowContainer} {
      visibility: visible;
    }

    ${Content} {
      &:before {
        border-color: ${palette('red')};
      }
    }
  }
`

export function SupplierCard({logo, text}: SupplierType): JSX.Element {
  return (
    <Card>
      <CardInner>
        <ArrowContainer>
          <ArrowRightUpLineIcon color={theme.palette.red} />
        </ArrowContainer>
        <Logo>
          {!!logo?.responsiveImage?.webpSrcSet && (
            <Image
              src={logo.responsiveImage.webpSrcSet}
              layout="fill"
              objectFit="contain"
              objectPosition="center"
            />
          )}
        </Logo>
        <Content>
          <DatoStructuredText content={text?.value} />
        </Content>
      </CardInner>
    </Card>
  )
}

export default function SupplierList({
  title,
  suppliers,
}: SupplierListFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <BlockTitle title={title ?? ''} />
        <Row>
          {suppliers?.map((props: SupplierType) => (
            <Col sm={4} md={3} lg={2} key={props.id}>
              {props.link ? (
                <Link href={props.link}>
                  <SupplierCard {...props} />
                </Link>
              ) : (
                <SupplierCard {...props} />
              )}
            </Col>
          ))}
        </Row>
      </Container>
    </BlockWrapper>
  )
}
