import React, {useEffect, useState} from 'react'
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'
import type {HeroBannerFields} from 'src/api/datocms/types/HeroBannerFields'
import {theme} from 'src/themes'
import {fluidRange} from 'src/utils/styles'
import {Col, Container, media, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import cn from 'classnames'
import type {Maybe} from 'src/utils/Maybe'
import {AnchorButton} from '../buttons'
import {H1, PLarge} from '../typography'

interface ImageProps {
  imgUrl: Maybe<string>
  imgBackground: Maybe<string>
}

const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 106px);

  ${media.md`
    height: calc(100vh - 162px);
  `}

  ${Container} {
    height: 100%;
    ${Row} {
      height: 100%;
      ${Col} {
        &:first-child {
          height: 40%;
        }
        &:last-child {
          height: 60%;
        }

        ${media.sm`
          &:first-child {
            height: 60%;
          }
          &:last-child {
            height: 40%;
          }
        `}

        ${media.md`
          &:first-child {
            height: 100%;
          }
          &:last-child {
            height: 100%;
          }
        `}
      }
    }
  }
`

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TextContent = styled.div`
  ${fluidRange('padding-top', '24px', '100px')};
`

const DesktopImageSection = styled.div<ImageProps>`
  ${({imgBackground}) =>
    imgBackground ? `background-image: url(${imgBackground});` : ''}

  &:before {
    ${({imgUrl}) => (imgUrl ? `background-image: url(${imgUrl});` : '')}
  }

  ${media.md`
    position: absolute;
    right: 0px;
    left: 50%;
    bottom: 0;
    top: -128px;
    background-position: center;
    background-size: cover;

    &:before {
      content: '';
      position: absolute;
      right: 0px;
      left: 0;
      bottom: 0;
      top: 0;
      background-position: center;
      background-size: cover;
    }
  `}
`

const MobileImageSection = styled.div<ImageProps>`
  width: 100%;
  position: absolute;
  right: 0px;
  left: 0%;
  bottom: 60%;
  top: -70px;
  bottom: 60%;
  background-position: center;
  background-size: cover;
  ${({imgBackground}) =>
    imgBackground ? `background-image: url(${imgBackground});` : ''}

  &:before {
    content: '';
    position: absolute;
    right: 0px;
    left: 0%;
    bottom: 0;
    top: 0;
    ${({imgUrl}) => (imgUrl ? `background-image: url(${imgUrl});` : '')}
    background-position: center;
    background-size: cover;
  }

  ${media.sm`
    bottom: 40%;
  `}

  ${media.md`
    display: none;
  `}
`

const Label = styled.div`
  color: ${palette('red')};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`

const ImagePlaceholder = styled.div`
  height: 100%;
`

const ArrowWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: calc(50% - 25px);
  height: 50px;
  width: 50px;
  background-color: ${palette('red')};
  opacity: 0;
  transition: opacity 0.5s ease-in;

  &.showArrow {
    opacity: 50%;
    cursor: pointer;
  }

  ${media.md`
    display: flex;
  `}
`

export default function HeroBanner({
  title,
  description,
  label,
  buttonLink,
  buttonText,
  image,
}: HeroBannerFields): JSX.Element {
  const [showArrow, setShowArrow] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setShowArrow(true)
    }, 3000)
  }, [])

  function handleArrowClick(): void {
    window.scroll({
      top: window.innerHeight - 155,
      behavior: 'smooth',
    })
  }

  return (
    <Wrapper>
      <MobileImageSection
        imgUrl={image?.responsiveImage?.src}
        imgBackground={image?.responsiveImage?.base64}
      />
      <Container>
        <Row>
          <Col col={12} hiddenMdUp>
            <ImagePlaceholder />
          </Col>
          <Col col={12} md={6}>
            <TextSection>
              <TextContent>
                <Label>{label}</Label>
                <H1>{title}</H1>
                <PLarge withGutter>{description}</PLarge>
                {buttonText && buttonLink && (
                  <AnchorButton
                    href={buttonLink}
                    icon={<ArrowRightLineIcon color={theme.palette.red} />}
                  >
                    {buttonText}
                  </AnchorButton>
                )}
              </TextContent>
            </TextSection>
          </Col>
        </Row>
        <DesktopImageSection
          imgUrl={image?.responsiveImage?.src}
          imgBackground={image?.responsiveImage?.base64}
        />
      </Container>
      <ArrowWrapper
        onClick={() => handleArrowClick()}
        className={cn({showArrow})}
      >
        <ArrowDownSLineIcon color={theme.palette.white} size={40} />
      </ArrowWrapper>
    </Wrapper>
  )
}
