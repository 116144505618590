import React, {useEffect} from 'react'

import {Col, Container, Row} from 'styled-bootstrap-grid'

import type {
  CollectionListFields,
  CollectionListFields_collections,
} from 'src/api/datocms/types/CollectionListFields'
import CollectionCard from '../collection/CollectionCard'
import {BlockWrapper} from '../layout'

export default function CollectionList({
  collections,
}: CollectionListFields): JSX.Element {
  const count = collections?.length
  const smSize = count > 1 ? 6 : 12
  const inBetweenSize = count > 2 ? 4 : smSize
  const mdSize = count > 3 ? 3 : inBetweenSize

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line array-callback-return
      collections.map((collection) => {
        sessionStorage.removeItem(`scrollPosition_${collection.collectionId}`)
      })
    }
  }, [collections])

  return (
    <BlockWrapper>
      <Container>
        <Row>
          {collections?.map((collection: CollectionListFields_collections) => (
            <Col key={collection.collectionId} col={12} sm={smSize} md={mdSize}>
              <CollectionCard {...collection} />
            </Col>
          ))}
        </Row>
      </Container>
    </BlockWrapper>
  )
}
