import React from 'react'

import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import type {ProductSliderFields} from 'src/api/datocms/types/ProductSliderFields'
import {CollectionDocument, useCollectionQuery} from 'src/api/saleor/generated'
import {Loader} from '../common'
import {BlockTitle, BlockWrapper} from '../layout'
import {ProductCard} from '../products'
import CustomSlider from '../common/CustomSlider'

const SliderBlockWrapper = styled.div`
  position: relative;
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
`

export default function ProductSlider({
  collection,
}: ProductSliderFields): JSX.Element | null {
  const {data, loading} = useCollectionQuery({
    query: CollectionDocument,
    skip: !collection?.collectionId,
    variables: {id: collection?.collectionId ?? '', first: 10},
  })

  if (loading) {
    return (
      <LoadingWrapper>
        <Loader size={32} />
      </LoadingWrapper>
    )
  }

  if (!data?.collection) {
    return null
  }

  const {name, products} = data?.collection
  return (
    <BlockWrapper>
      <SliderBlockWrapper>
        <Container>
          <CustomSlider
            xl={4}
            lg={3}
            md={2}
            sm={1.1}
            title={<BlockTitle title={name} />}
          >
            {products?.edges
              ?.map((product) => (
                <ProductCard key={product.node.id} slug={product.node.slug} />
              ))
              .filter(Boolean)}
          </CustomSlider>
        </Container>
      </SliderBlockWrapper>
    </BlockWrapper>
  )
}
