import React from 'react'
import type {
  ValueListFields,
  ValueListFields_values as ValueType,
} from 'src/api/datocms/types/ValueListFields'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, prop} from 'styled-tools'
import shadowStyles from '../../styles/shadowStyles'
import {DatoStructuredText} from '../common'
import {BlockTitle, BlockWrapper} from '../layout'
import {H5} from '../typography'

interface ImageProps {
  backgroundImg: string
}

const ValueImage = styled.div<ImageProps>`
  background-size: cover;
  background-position: center;
  background-image: url('${prop('backgroundImg')}');
  height: 125px;
  margin-bottom: 32px;
  ${shadowStyles({
    color: 'red',
    outlined: true,
    length: 8,
    showOnHover: false,
  })};
`

const ValueCard = styled.div`
  border: 1px solid ${palette('black10')};
  padding: 32px;
  height: 100%;
`

const ValueWrapper = styled.div`
  height: 100%;
  padding-bottom: 24px;
`

export function Value({image, title, text}: ValueType): JSX.Element {
  return (
    <ValueWrapper>
      <ValueCard>
        {image?.responsiveImage?.src ? (
          <ValueImage backgroundImg={image?.responsiveImage.src} />
        ) : null}
        <H5 withGutter>{title}</H5>
        <DatoStructuredText content={text} />
      </ValueCard>
    </ValueWrapper>
  )
}

export default function ValueList({
  title,
  small,
  values,
}: ValueListFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <BlockTitle title={title ?? ''} />
        <Row>
          {values?.map((props: ValueType) => (
            <Col sm={6} md={small ? 3 : 6} key={props.id}>
              <Value {...props} />
            </Col>
          ))}
        </Row>
      </Container>
    </BlockWrapper>
  )
}
