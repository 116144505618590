import React from 'react'
import MailFillIcon from 'remixicon-react/MailFillIcon'
import PhoneFillIcon from 'remixicon-react/PhoneFillIcon'
import type {
  EmployeeListFields,
  EmployeeListFields_employees as EmployeeType,
} from 'src/api/datocms/types/EmployeeListFields'
import {theme} from 'src/themes'
import {Col, Container, media, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, prop} from 'styled-tools'
import {NoImage} from '../common'
import {BlockWrapper} from '../layout'
import {P, PSmall, Title5, Title7} from '../typography'

const EmployeeWrapper = styled.div`
  padding-bottom: 24px;
  height: 100%;
`

const Employee = styled.div`
  display: flex;
  border: 1px solid ${palette('black20')};
  min-height: 140px;
  height: 100%;

  ${media.md`
    min-height: 200px;
  `}
`

interface ImageProps {
  backgroundImg: string
}

const EmployeeImage = styled.div<ImageProps>`
  background-repeat: no-repeat;
  background-position: top-center;
  background-image: url('${prop('backgroundImg')}');
  min-width: 120px;

  ${media.md`
    min-width: 160px;
  `}

  @media only screen and (max-width:600px) {
    background-size: cover;
    background-position: top-center;
    width: 120px;
    height: 100%;
  }
`

const Line = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  a {
    transition: color 0.25s;
    &:hover {
      color: ${palette('red')};
    }
  }
`

const EmployeeInfo = styled.div`
  padding: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    width: 20px;
    border-top: 1px solid ${palette('red')};
  }

  ${media.sm`
    padding: 24px;

    &:before {
      top: 16px;
    }

    ${Title7} {
      margin-bottom: 8px;
    }
    ${P} {
      margin-bottom: 8px;
    }
  `}
`

const EmployeeListWrapper = styled.div``

const Comment = styled.div`
  margin-top: 16px;
  border-top: 1px solid ${palette('black20')};
  font-style: italic;
  padding-top: 16px;
`

export default function EmployeeList({
  title,
  employees,
}: EmployeeListFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <Title5 withGutter>{title}</Title5>
        <EmployeeListWrapper>
          <Row>
            {employees?.map(
              ({
                id,
                name,
                email,
                role,
                mobilePhone,
                officePhone,
                comment,
                image,
              }: EmployeeType) => (
                <Col sm={6} md={4} key={id}>
                  <EmployeeWrapper>
                    <Employee>
                      {image?.url ? (
                        <EmployeeImage backgroundImg={image?.url} />
                      ) : (
                        <NoImage
                          size={40}
                          text="Mynd væntanleg"
                          textSize="14"
                        />
                      )}
                      <EmployeeInfo>
                        <Title7>{name}</Title7>
                        <P>{role}</P>
                        {!!email && (
                          <Line>
                            <MailFillIcon size={16} color={theme.palette.red} />
                            <PSmall bold>
                              <a href={`mailto:${email}`}>{email}</a>
                            </PSmall>
                          </Line>
                        )}
                        {!!comment && <Comment>{comment}</Comment>}
                        {!comment && !!officePhone && (
                          <Line>
                            <PhoneFillIcon
                              size={16}
                              color={theme.palette.red}
                            />
                            <PSmall bold>{officePhone}</PSmall>
                          </Line>
                        )}
                        {!comment && !!mobilePhone && (
                          <Line>
                            <PhoneFillIcon
                              size={16}
                              color={theme.palette.red}
                            />
                            <PSmall bold>{mobilePhone}</PSmall>
                          </Line>
                        )}
                      </EmployeeInfo>
                    </Employee>
                  </EmployeeWrapper>
                </Col>
              ),
            )}
          </Row>
        </EmployeeListWrapper>
      </Container>
    </BlockWrapper>
  )
}
