import React from 'react'
import type {VideoBlockFields} from 'src/api/datocms/types/VideoBlockFields'

import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import Video from '../common/Video'
import {BlockWrapper} from '../layout'

const VideoWrapper = styled.div`
  max-width: 800px;
  margin: auto;
`

export default function VideoBlock(props: VideoBlockFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <VideoWrapper>
          <Video {...props} />
        </VideoWrapper>
      </Container>
    </BlockWrapper>
  )
}
