/* eslint-disable @next/next/no-img-element */
import React, {useEffect, useState, useRef} from 'react'
import type {BannerFields} from 'src/api/datocms/types/BannerFields'
import {Col, Row, Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {useWindowSize} from 'react-use'

interface BannerCollectionProps {
  brochures: BannerFields[]
}

interface BannerProps {
  image: string | undefined
  link: string | null
  handleImageClick: (link: string) => void
  title: string | null
  isMobile: boolean
}

const ViewBanner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  .iframe {
    width: 98%;
    height: calc(100vh - 100px);
  }
  .mobile-iframe {
    width: 92%;
    height: calc(100vh - 240px);
  }
  .mobile-close {
    position: absolute;
    right: 20px;
  }
  .desktop-close {
    position: absolute;
    right: 90px;
  }
`
const CloseButton = styled.button`
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 24px;
  text-shadow: rgb(0 0 0) 0px 0px 3px;
  text-transform: uppercase;
  float: right;
  margin-top: 12px;
  background: transparent;
  border: 0px;
  color: ${palette('red')};
`
const BannerContainer = styled.div`
  .desktop {
    margin: 10px 75px;
  }
  .mobile {
    margin: 0 25px;
  }
  .banner-wrapper {
    margin-top: 50px;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    :hover {
      border: 1px solid red;
    }
  }
  .banner {
    background-color: rgb(254, 254, 254);
    border: 1px solid rgb(209, 209, 209);
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    transition: transform 0.5s ease 0s;
    :hover {
      transform: translateY(-8px) translatex(8px);
    }
    cursor: pointer;
  }
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
  .img {
    border: none !important;
    height: 300px;
    object-fit: contain;
  }
  .mobile-img {
    border: none !important;
    height: 350px;
    object-fit: contain;
  }
`
function Banner({
  image,
  link,
  handleImageClick,
  title,
  isMobile,
}: BannerProps): JSX.Element {
  if (image && link) {
    return (
      <div className="banner-wrapper">
        <button
          type="button"
          className="banner"
          onClick={() => handleImageClick(link)}
        >
          <div>
            <div className="title">{title}</div>
            <img
              src={image}
              className={isMobile ? 'mobile-img' : 'img'}
              alt=""
            />
          </div>
        </button>
      </div>
    )
  }
  return <></>
}

export default function BannerCollection({
  brochures,
}: BannerCollectionProps): JSX.Element | null {
  const {width} = useWindowSize()
  const bannerRef = useRef<HTMLDivElement | null>(null)
  const [columns, setColumns] = useState<number>(3)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [selected, setSelected] = useState<string | undefined>()

  const handleImageClick = (link: string): void => {
    const decoded = link.replace('amp;', '')
    setSelected(decoded)
  }

  const handleClose = (): void => {
    setSelected(undefined)
  }

  const handleKeypress = (event: KeyboardEvent): void => {
    if (event.code === 'Escape' && selected) {
      setSelected(undefined)
    }
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (width > 1024) {
      return setColumns(3)
    }
    if (width <= 1150 && width > 900) {
      return setColumns(4)
    }
    if (width <= 900 && width >= 768) {
      if (isMobile) {
        setIsMobile(false)
      }
      return setColumns(6)
    }
    if (width < 768) {
      setIsMobile(true)
      return setColumns(12)
    }
  }, [isMobile, width])

  useEffect(() => {
    if (selected && isMobile) {
      bannerRef.current?.scrollIntoView({block: 'center'})
    }
  }, [selected, isMobile])

  useEffect(() => {
    document.addEventListener('mousedown', (event: MouseEvent) => {
      if (!bannerRef.current?.contains(event.target as Node | null)) {
        setSelected(undefined)
      }
    })

    document.addEventListener('keydown', handleKeypress)
    return () => {
      document.removeEventListener('keydown', handleKeypress)
    }
  })

  if (!brochures || !brochures.length) {
    return null
  }
  const banners: BannerFields[] = brochures?.filter(
    (entry) => entry != null,
  ) as BannerFields[]

  return (
    <>
      {selected && (
        <Container>
          <ViewBanner ref={bannerRef}>
            <CloseButton
              type="button"
              onClick={handleClose}
              className={isMobile ? 'mobile-close' : 'desktop-close'}
            >
              close
            </CloseButton>
            <iframe
              className={isMobile ? 'mobile-iframe' : 'iframe'}
              title={selected}
              sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
              allow="clipboard-write"
              allowFullScreen
              src={selected}
            />
          </ViewBanner>
        </Container>
      )}
      {!selected && (
        <BannerContainer>
          <div className={isMobile ? 'mobile' : 'desktop'}>
            <Container>
              <Row>
                {banners.map((banner) => (
                  <Col col={columns}>
                    <Banner
                      key={banner.id}
                      image={banner.image?.url as string}
                      link={banner.link}
                      handleImageClick={handleImageClick}
                      title={banner.title as string}
                      isMobile={isMobile}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </BannerContainer>
      )}
    </>
  )
}
