import React from 'react'

import Image from 'next/image'
import type {ImageBlockFields} from 'src/api/datocms/types/ImageBlockFields'
import {Container as _Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {BlockWrapper} from '../layout'

const Container = styled(_Container)`
  position: relative;
  max-width: 100%;
  height: 20vh;
`

export default function ImageBlock({
  image,
}: ImageBlockFields): JSX.Element | null {
  if (!image?.responsiveImage?.src) {
    return null
  }
  return (
    <BlockWrapper>
      <Container>
        <Image layout="fill" src={image?.responsiveImage.webpSrcSet} />
      </Container>
    </BlockWrapper>
  )
}
