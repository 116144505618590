import React from 'react'
import type {BannerFields} from 'src/api/datocms/types/BannerFields'
import type {Maybe} from 'src/utils/Maybe'
import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import AnchorButton from '../buttons/AnchorButton'
import {BlockWrapper} from '../layout'

interface BannerProps {
  backgroundImg?: Maybe<string>
  heightMob?: Maybe<number>
}

const BannerWrapper = styled.div<BannerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({backgroundImg}) =>
    backgroundImg ? `background-image: url('${backgroundImg}');` : ''}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 200px;

  a {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    background-position: center !important;
    ${({heightMob}) =>
      heightMob ? `min-height: ${heightMob}px` : 'min-height: 150px;'}
  }
`

const BannerTitle = styled.div`
  font-family: 'LetterboardLite';
  font-style: normal;
  font-weight: normal;
  font-size: 80px;
  line-height: 100px;
  text-align: center;
  color: ${palette('white')};

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
    padding: 5px;
  }
`

const BannerDescription = styled.div`
  font-family: 'LetterboardLite';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  background-color: ${palette('black')};
  color: ${palette('white')};
  padding: 0 10px;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
    padding: 5px;
  }
`

const BannerInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default function Banner({
  title,
  description,
  image,
  link,
  linkLabel,
  heightMob,
}: BannerFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        {title && description && linkLabel && (
          <BannerWrapper
            heightMob={heightMob}
            backgroundImg={image?.responsiveImage?.src}
          >
            <BannerInner>
              <BannerTitle>{title}</BannerTitle>
              <BannerDescription>{description}</BannerDescription>
              {link && linkLabel && (
                <AnchorButton color="black" outlined href={link} withGutter>
                  {linkLabel}
                </AnchorButton>
              )}
            </BannerInner>
          </BannerWrapper>
        )}
        {!linkLabel && link && (
          <a href={link as string}>
            <BannerWrapper
              heightMob={heightMob}
              backgroundImg={image?.responsiveImage?.src}
            >
              <BannerInner>
                {title && <BannerTitle>{title}</BannerTitle>}
                {description && (
                  <BannerDescription>{description}</BannerDescription>
                )}
              </BannerInner>
            </BannerWrapper>
          </a>
        )}
      </Container>
    </BlockWrapper>
  )
}
