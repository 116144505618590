import React from 'react'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import type {PageBannerFields} from 'src/api/datocms/types/PageBannerFields'
import {Image} from '../common'

interface LinkProps {
  children: React.ReactNode
}

const BannerImage = styled(Container)`
  ${media.md`
    margin-top: 40px;
  `}
  ${media.lg`
    margin-top: 0px;
  `}
`

export default function PageBanner({
  desktopImage,
  link,
}: PageBannerFields): JSX.Element {
  const BannerLink = ({children}: LinkProps): JSX.Element =>
    // eslint-disable-next-line react/jsx-no-useless-fragment
    link ? <a href={link}>{children}</a> : <>{children}</>
  return (
    <BannerLink>
      {desktopImage?.responsiveImage?.src ? (
        <BannerImage>
          <Image
            useImg
            objectFit="cover"
            src={desktopImage?.responsiveImage?.src}
            alt={desktopImage?.responsiveImage?.alt ?? ''}
          />
        </BannerImage>
      ) : null}
    </BannerLink>
  )
}
