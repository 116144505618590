/* eslint-disable @typescript-eslint/no-explicit-any */
/* Incorrect typing for this one datocms guy */

import React from 'react'
import type {TextBlockFields} from 'src/api/datocms/types/TextBlockFields'
import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import DatoStructuredText from '../common/DatoStructuredText'
import {BlockWrapper} from '../layout'

const Wrapper = styled.div`
  max-width: 800px;
  margin: auto;
`

export default function TextBlock({text}: TextBlockFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <Wrapper>
          <DatoStructuredText content={text as any} />
        </Wrapper>
      </Container>
    </BlockWrapper>
  )
}
