import type {HTMLAttributes} from 'react'
import React, {useCallback, useRef, useState, useEffect} from 'react'

import cn from 'classnames'
import {ceil, size} from 'lodash'
import Slider from 'react-slick'
import ReactSlider from 'react-slider'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {gridTheme} from 'src/themes'
import {ArrowButton} from '../buttons'

interface SliderProps {
  children: React.ReactNode
  sm: number // slidesToShow in mobile
  md: number // slidesToShow in tablet
  lg: number // slidesToShow in laptop
  xl: number // slidesToShow in desktop
  title?: React.ReactNode
  small?: boolean
}

interface ThumbProps {
  totalSlideSteps?: number
}

const Wrapper = styled.div`
  position: relative;

  .slick-list {
    overflow: visible !important;
    backface-visibility: hidden;
    perspective: 2000px;
  }

  /* Fix for adaptive height in react-slick */
  .slick-slide {
    height: auto;

    & > div {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }
  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
`

const SlideWrapper = styled.div`
  padding-right: 16px;

  &:focus {
    outline: none !important;
  }
`

const SliderHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TitleContentWrapper = styled.div`
  flex-grow: 1;
`

const SliderBtnsWrapper = styled.div`
  display: none;
  align-items: center;
  margin-bottom: 24px;
  margin-right: 16px;

  ${media.sm`
    display: flex;

    &.hideOnSm {
      display: none;
    }

  `}

  ${media.md`
    &.hideOnMd {
      display: none;
    }
  `}

  ${media.lg`
    &.hideOnLg {
      display: none;
    }
  `}

  ${media.desktop`
    &.hideOnXl {
      display: none;
    }
  `}
`

const MiniSliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;

  ${media.sm`
    display: flex;

    &.hideOnSm {
      visibility: hidden;
    }

  `}

  ${media.md`
    &.hideOnMd {
      visibility: hidden;
    }
  `}

  ${media.lg`
    &.hideOnLg {
      visibility: hidden;
    }
  `}

  ${media.desktop`
    &.hideOnXl {
      visibility: hidden;
    }
  `}
`

export const MiniSlider = styled(ReactSlider)`
  width: 80%;
  max-width: 420px;
  height: 16px;
  cursor: pointer;
`

const MiniSliderTrack = styled.div`
  top: 7px;
  bottom: 7px;
  background: ${palette('black20')};
  border-radius: 0;
`

const MiniSliderThumb = styled.div<ThumbProps>`
  height: 6px;
  width: ${(p) => 100 / (p.totalSlideSteps || 1)}%;
  margin: 5px 0;
  border: 0;
  border-radius: 0;
  background-color: ${palette('red')};
  cursor: grab;

  span {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &.darkMode {
    background-color: ${palette('white')};
  }
`

const HiddenDots = styled.div`
  display: none;
`

const SliderWrapper = styled.div`
  &:hover {
    ~ ${MiniSliderWrapper} {
      ${MiniSlider} {
        z-index: -1;
      }
    }
  }

  ${media.md`
    overflow: hidden;
    padding-top: 10px;
  `}
`

let firstClientX: number
let clientX: number

const preventTouch = (e: React.TouchEvent): boolean => {
  const minValue = 5 // threshold

  clientX = e.touches[0].clientX - firstClientX

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault()

    return false
  }

  return true
}

const touchStart = (e: React.TouchEvent): void => {
  firstClientX = e.touches[0].clientX
}

export default function CustomSlider({
  title,
  children,
  sm,
  md,
  lg,
  xl,
}: SliderProps): JSX.Element {
  const [currSlide, setCurrSlide] = useState<number>(0)
  const sliderRef = useRef<Slider>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const containerRef = useRef<any>(null)
  const [totalSlideSteps, setTotalSlideSteps] = useState<number>(
    size(children as React.ReactNode[]),
  )
  const dotsRef: React.RefObject<HTMLDivElement> = useRef(null)

  const childrenCount = React.Children.count(children)
  const hideOnXl = childrenCount <= xl
  const hideOnLg = childrenCount <= lg
  const hideOnMd = childrenCount <= md
  const hideOnSm = childrenCount <= sm

  const onReInitSlider = useCallback(
    () => setTotalSlideSteps(dotsRef.current?.childElementCount ?? 0),
    [dotsRef, setTotalSlideSteps],
  )

  const changeSlide = useCallback(
    (_: number, index: number) => setCurrSlide(ceil(index)),
    [setCurrSlide],
  )

  const onClickPrev = useCallback(() => {
    if (currSlide > 0) {
      sliderRef?.current?.slickPrev()
      setCurrSlide(currSlide - 1)
    }
  }, [currSlide, sliderRef, setCurrSlide])

  const onClickNext = useCallback(() => {
    if (currSlide < totalSlideSteps - 1) {
      sliderRef?.current?.slickNext()
      setCurrSlide(currSlide + 1)
    }
  }, [totalSlideSteps, currSlide, sliderRef, setCurrSlide])

  const onChangeMiniSlider = useCallback(
    (index: number | readonly number[], _: number) => {
      if (Array.isArray(index)) {
        return
      }
      sliderRef.current?.slickGoTo(index as number)
      setCurrSlide(index as number)
    },
    [sliderRef, setCurrSlide],
  )

  const settings = {
    infinite: false,
    arrows: false,
    speed: 400,
    slidesToShow: xl,
    slidesToScroll: 1,
    waitForAnimate: false,
    swipeToSlide: true,
    dots: true,
    appendDots: (dots: React.ReactNode[]) => (
      <HiddenDots ref={dotsRef}>{dots}</HiddenDots>
    ),
    beforeChange: changeSlide,
    onReInit: onReInitSlider,
    useTransform: false,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: gridTheme?.breakpoints?.lg,
        settings: {
          slidesToShow: lg,
        },
      },
      {
        breakpoint: gridTheme?.breakpoints?.md,
        settings: {
          slidesToShow: md,
        },
      },
      {
        breakpoint: gridTheme?.breakpoints?.sm,
        settings: {
          speed: 200,
          slidesToShow: sm,
        },
      },
    ],
  }

  useEffect(() => {
    const currentRef = containerRef.current

    if (currentRef) {
      currentRef.addEventListener('touchstart', touchStart)
      currentRef.addEventListener('touchmove', preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('touchstart', touchStart)
        currentRef.removeEventListener('touchmove', preventTouch, {
          passive: false,
        })
      }
    }
  })

  return (
    <Wrapper className="slider-wrapper">
      <SliderHeader>
        <TitleContentWrapper className="slider-title">
          {title}
        </TitleContentWrapper>
        <SliderBtnsWrapper
          className={cn({hideOnXl, hideOnLg, hideOnMd, hideOnSm})}
        >
          <ArrowButton direction="left" onClick={onClickPrev} outlined />
          <ArrowButton
            direction="right"
            onClick={onClickNext}
            outlined
            style={{marginLeft: '16px'}}
          />
        </SliderBtnsWrapper>
      </SliderHeader>
      <SliderWrapper ref={containerRef}>
        <Slider ref={sliderRef} {...settings}>
          {React.Children.map(children, (child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SlideWrapper
              key={
                typeof child === 'object' && child != null && 'key' in child
                  ? child.key
                  : index
              }
            >
              {child}
            </SlideWrapper>
          ))}
        </Slider>
      </SliderWrapper>
      <MiniSliderWrapper
        className={cn({hideOnXl, hideOnLg, hideOnMd, hideOnSm})}
      >
        <MiniSlider
          min={0}
          max={totalSlideSteps - 1}
          value={currSlide}
          renderTrack={(props: HTMLAttributes<HTMLDivElement>) => (
            <MiniSliderTrack {...props} />
          )}
          renderThumb={(props: HTMLAttributes<HTMLDivElement>) => (
            <MiniSliderThumb {...props} totalSlideSteps={totalSlideSteps} />
          )}
          onChange={onChangeMiniSlider}
        />
      </MiniSliderWrapper>
    </Wrapper>
  )
}
