import type {Ref} from 'react'
import React, {useEffect, useRef, useState} from 'react'
import type {Page_page_content as Content} from 'src/api/datocms/types/Page'
import {useNavigationCategoriesQuery} from 'src/api/saleor/generated'
import styled from 'styled-components'
import {generatePath, paths} from 'src/utils/paths'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'
import {theme} from 'src/themes'
import {usePageContext} from 'src/context/PageContext'
import type {NavCategoryFragment} from 'src/api/saleor/generated'
import {useRouter} from 'next/router'
import scrollStyle from 'src/styles/scrollStyle'
import {Title7} from '../typography'
import {Link} from '../common'
import {contentMap} from './content-map'

interface Props {
  content: (Content | null)[] | null
}
interface CategoryProps {
  selected?: boolean
}

interface PageContainerProps {
  scrollValue: number
  pageHeight: number
  sidebarHeight: number
  subRef?: Ref<HTMLInputElement> | null
}

const Category = styled.div<CategoryProps>`
  transition: width 0.2s ease-in-out;
  padding: 0px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  a {
    margin: 0px;
    padding: 0px;
  }
  .remixicon-icon {
    width: ${({selected}) => (selected ? '20px' : '0px')};
    visibility: ${({selected}) => (selected ? 'visible' : 'hidden')};
    margin-right: ${({selected}) => (selected ? '6px' : '0px')};
    transition: width 0.2s ease-in-out;
  }
  &:hover {
    color: ${theme.palette.red};
    .remixicon-icon {
      margin-right: 6px;
      width: 20px;
      visibility: visible;
    }
  }
`

const PageSection = styled.div`
  display: flex;
  padding: 10px 20px;
  max-width: 1810px;
  margin: auto;
  position: relative;
  @media screen and (max-width: ${theme.screenSize.sizeMD}) {
    padding: 0px;
  }
`
const CategorySidebar = styled.div<PageContainerProps>`
  position: ${(props) =>
    props.scrollValue >= props.pageHeight - props.sidebarHeight
      ? 'absolute'
      : 'fixed'};

  top: ${(props) =>
    props.scrollValue >= props.pageHeight - props.sidebarHeight
      ? `${props.pageHeight - props.sidebarHeight}px`
      : 'inherit'};

  padding-top: 20px;
  width: 15%;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${Title7} {
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
    &:hover {
      color: ${theme.palette.red};
    }
  }
  @media screen and (max-width: ${theme.screenSize.sizeMD}) {
    display: none;
  }
  @media screen and (max-height: 700px) {
    overflow-x: hidden;
    ${scrollStyle({color: 'red'})}
  }
`
const CmsContent = styled.div`
  width: 85%;
  margin-left: auto;
  @media screen and (max-width: ${theme.screenSize.sizeMD}) {
    width: 100%;
  }
`

export function DatocmsContent({content}: Props): JSX.Element | null {
  const [categories, setCategories] = useState<string[]>()
  const [categoryMap, setCategoryMap] = useState<
    Record<string, NavCategoryFragment>
  >()

  const [scrollValue, setScrollValue] = useState<number>(0)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const pageRef = useRef<HTMLDivElement>(null)

  const {pathname} = useRouter()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {data: categoriesData} = useNavigationCategoriesQuery()
  const {navigationInfo} = usePageContext()

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleScroll = () => {
    setScrollValue(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const catArr: string[] | undefined = navigationInfo?.navBlocks?.map(
      // eslint-disable-next-line no-return-assign
      (block) => block.slug,
    )
    setCategories(catArr)
  }, [navigationInfo])

  useEffect(() => {
    const categories_map: Record<string, NavCategoryFragment> = {}
    categoriesData?.categories?.edges?.forEach((cat) => {
      const {node} = cat
      categories_map[node.slug] = node
    })
    setCategoryMap(categories_map)
  }, [categoriesData])

  if (!content) {
    return null
  }
  const entries: Content[] = content.filter(
    (entry) => entry != null,
  ) as Content[]

  const isHomePage = pathname === '/'

  return isHomePage ? (
    <PageSection ref={pageRef}>
      <CategorySidebar
        pageHeight={pageRef?.current?.offsetHeight || 0}
        sidebarHeight={sidebarRef?.current?.offsetHeight || 0}
        scrollValue={scrollValue}
        ref={sidebarRef}
      >
        <>
          <Link href={paths.categories}>
            <Title7 bottom={5} top={10}>
              Allir vöruflokkar
            </Title7>
          </Link>
        </>
        {categories &&
          categoryMap &&
          categories.map((cat) => (
            <Category key={categoryMap && categoryMap[cat]?.id}>
              <ArrowRightLineIcon color={theme.palette.red} />
              <Link
                href={generatePath(
                  paths.categories,
                  categoryMap && categoryMap[cat]?.slug,
                  'landingPage=true',
                )}
                shallow
              >
                <Title7>{categoryMap && categoryMap[cat]?.name}</Title7>
              </Link>
            </Category>
          ))}
      </CategorySidebar>
      <CmsContent>
        {entries.map((entry: Content) => {
          const Component = contentMap[entry.__typename]
          return Component ? <Component key={entry.id} {...entry} /> : null
        })}
      </CmsContent>
    </PageSection>
  ) : (
    <>
      {entries.map((entry: Content) => {
        const Component = contentMap[entry.__typename]
        return Component ? <Component key={entry.id} {...entry} /> : null
      })}
    </>
  )
}
